import { createRouter, createWebHashHistory } from 'vue-router';
import About from '@/pages/aboutPage/about.vue';
import Home from '@/pages/landing/landing.vue';
import ItSecurity from "@/pages/program/it-security/it-security";
import ItSystems from "@/pages/program/it-systems/it-systems";
import MathCompModeling from "@/pages/program/math-comp-modeling/math-comp-modeling";
import ComputerScience from "@/pages/program/computer-science/computer-science";


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/program/it-security',
        name: 'ItSecurity',
        component: ItSecurity
    },
    {
        path: '/program/it-systems',
        name: 'ItSystems',
        component: ItSystems
    },
    {
        path: '/program/math-comp-modeling',
        name: 'MathCompModeling',
        component: MathCompModeling
    },
    {
        path: '/program/computer-science',
        name: 'ComputerScience',
        component: ComputerScience
    },
];

export const router = createRouter({
    history: createWebHashHistory(),
    routes
});
