<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/mkm.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <p class="top_edu">
          <span>Магистратура</span>
          <span>Бакалавриат</span>
        </p>
        <h3>Математическое и <br/>компьютерное моделирование</h3>
        <p style="border-bottom: 2px solid #FFA51E;width:80px;">6B06103</p>
        <router-link to="#application"><button>Получить консультацию</button></router-link>
      </div>
    </div>
    <div class="container px-5 ">
      <p><b class="blue_bold">Программа</b> по математическому и компьютерному моделированию направлена на подготовку квалифицированных специалистов, способных решать сложные задачи в области математического и компьютерного моделирования. Цель программы — предоставить студентам прочные знания в области математики, вычислительных технологий и анализа данных, а также развить навыки применения этих знаний для создания и анализа моделей, которые помогают принимать обоснованные решения в различных областях науки и бизнеса.</p>

      <p><b class="blue_bold">Задачи профессиональной деятельности:</b></p>
      <ul>
        <li>Разработка и анализ математических моделей: Создание, анализ и оптимизация моделей для описания и прогнозирования сложных процессов в науке, технике и экономике.</li>
        <li>Программирование и алгоритмическое обеспечение: Разработка программного обеспечения для реализации математических моделей и алгоритмов, включая программирование, тестирование и отладку.</li>
        <li>Анализ данных и визуализация: Обработка больших объемов данных, использование методов статистического анализа и визуализации для выявления закономерностей и поддержки принятия решений.</li>
        <li>Исследование и внедрение новых методов: Исследование новейших методик и технологий в области математического моделирования и их внедрение в практические задачи.</li>
        <li>Междисциплинарное сотрудничество: Работать в команде с профессионалами из других областей для разработки комплексных решений и улучшения моделей.</li>
      </ul>


      <p><b class="blue_bold">Варианты трудоустройства:</b></p>
      <ul>
        <li>Аналитики данных: Работа в компаниях, занимающихся обработкой и анализом больших данных, финансовыми и инвестиционными анализами.</li>
        <li>Разработчики программного обеспечения: Создание и поддержка программных решений, использующих математическое моделирование и алгоритмы.</li>
        <li>Инженеры по моделированию и симуляциям: Применение математических моделей для решения инженерных задач в различных отраслях, таких как машиностроение, аэрокосмическая промышленность, и другие.</li>
        <li>Научные исследователи: Проведение исследований в академических и исследовательских институтах, работа над новыми методами моделирования и анализа.</li>
        <li>Консультанты по математическому моделированию: Консультирование организаций по вопросам разработки и применения моделей для оптимизации бизнес-процессов и принятия решений.</li>
        <li>Профессионалы в области финансов: Анализ рисков, оценка инвестиционных проектов и разработка финансовых моделей.</li>
      </ul>

      <p>Программа бакалавриата по математическому и компьютерному моделированию предлагает студентам не только глубокие теоретические знания, но и практические навыки, которые позволяют эффективно применять методы моделирования в самых различных сферах профессиональной деятельности.</p>
    </div>
    <div class="wrapper_four container py-5 mt-3" id="profession" style="height: auto;">
      <Partners/>
    </div>
    <ContactForm/>
  </div>
</template>

<script>
  import ContactForm from "../../../components/ContactForm";
  import Partners from "../../../components/Partners";

  export default {
    name: "MathCompModelingPage",
    components: {
      ContactForm,
      Partners
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './math-comp-modeling.scss';
@import '../../landing/landing.scss';
</style>