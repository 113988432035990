<template>
  <div id="app">
    <Header/>
    <router-view></router-view>
    <Footer/>
    <WhatsAppComponent/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import {defineComponent} from "vue";
export default defineComponent({
  components: { Footer, Header}
})
</script>

<script setup>
import { watch, onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import WhatsAppComponent from "./components/WhatsApp";

const route = useRoute();
const router = useRouter();

onMounted(() => {
  scrollIfHashRoute();
});

const scrollIfHashRoute = () => {
  console.log(route.hash);
  if (route.hash.length > 0) {
    const element = document.querySelector(route.hash);
    if (element) {
      const positionHeight = element.getBoundingClientRect();
      window.scrollTo(0, positionHeight.top + window.scrollY);
    }
  }
};

watch (
    () => route.hash,
    (current, prev) => {
      scrollIfHashRoute();
    }
);

// Watch for click events on router-links
document.addEventListener('click', (event) => {
  const link = event.target.closest('a.router-link-active');
  if (link) {
    const currentHash = route.hash;
    if (currentHash) {
      router.push({ hash: '' }).then(() => {
        router.push({ hash: currentHash });
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
});
</script>

<style>
#app {
  font-family: Monrope, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
