<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <p class="top_edu">
          <span>Магистратура</span>
          <span>Бакалавриат</span>
        </p>
        <h3>Информационные<br/>системы</h3>
        <p style="border-bottom: 2px solid #FFA51E;width:80px;">6В06106</p>
        <router-link to="#application"><button>Получить консультацию</button></router-link>
      </div>
    </div>
    <div class="container px-5 ">
      <p><b class="blue_bold">Цели образовательной программы</b> “Информационные системы” обеспечение комплексной и качественной подготовки квалифицированных, конкурентоспособных специалистов в различных областях применения информационных систем и технологий.</p>

      <p><b class="blue_bold">Задачи профессиональной деятельности:</b></p>
      <ul>
        <li>применять знания гуманитарных, экологических, социально-правовых, экономических наук, физики и высшей математики в всех сферах деятельности;</li>
        <li>разрабатывать структуры данных и алгоритмы решения задач, выполнять их программную реализацию, сопровождение информационных систем и сетей;</li>
        <li>уметь разрабатывать, применять математические и/или имитационные модели и методы при проектировании различных процессов в информационных системах;</li>
        <li>проектировать и управлять базами данных информационных систем.;</li>
        <li>владеть основами администрирования компьютерных систем и сетей;</li>
        <li>уметь проектировать архитектуру информационной системы;</li>
        <li>аргументировать выбор системного, инструментального и прикладного программного обеспечения;</li>
        <li>разрабатывать экспертные, интеллектуальные и роботехнические системы. Выбирать оптимальные решения при их проектировании;</li>
        <li>проектировать эргономичный дизайн информационных систем;</li>
        <li>владеть современными средствами и средами разработки мобильных, сетевых приложений;</li>
        <li>проектировать алгоритмы и разрабатывать методы информационной безопасности и надежности систем;</li>
        <li>выявлять потребности организации в информатизации, формировать требования к информационным процессам и системам, предлагать оптимальное решение;</li>

      </ul>

      <p><b class="blue_bold">Варианты трудоустройства:</b></p>
      <ul>
        <li>ведущих компьютерных компаниях «Kaspi», «Kolesa», «OneTech»;</li>
        <li>сфере телекоммуникаций и связи («Казахтелеком», «К-СеІІ», «Beeline», «Dalacom», «Neo»);</li>
        <li>отделах компаний, связанных с сетевым администрированием, администрированием баз данных, защитой информации, разработкой программного обеспечения, работой с вычислительной техникой, микропроцессорными системами, компьютерными и сетевыми технологиями, а именно: Seiko Epson Со, Samba Construction, ТОО «OMEGA.KZ», «Alfa technologies», «ALTERNATE», TOO «N-Com», «Newinttech», BP Solution и других передовых, успешно развивающихся организациях;</li>
      </ul>
    </div>
    <div class="wrapper_four container py-5 mt-3" id="profession" style="height: auto;">
      <Partners/>
    </div>
    <ContactForm/>
  </div>
</template>

<script>
  import ContactForm from "../../../components/ContactForm";
  import Partners from "../../../components/Partners";

  export default {
    name: "ItSystemsPage",
    components: {
      ContactForm,
      Partners
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './it-systems.scss';
@import '../../landing/landing.scss';
</style>