<template>
  <div class="our_partners mt-5" id="partners">
    <div class="title ">
      <h1>Наши партнеры</h1>
      <hr>
    </div>
    <div class="main">
      <h1 class="py-4">Наши партнеры</h1>
      <div class="d-flex justify-content-around align-items-center w-100">
        <img src="@/assets/img/logo1.png" alt="$">
        <img src="@/assets/img/logo2.png" alt="$">
        <img src="@/assets/img/logo3.png" alt="$">
        <img src="@/assets/img/logo4.png" alt="$">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnersComponent",
}
</script>