<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-security.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <p class="top_edu">
          <span>Магистратура</span>
          <span>Бакалавриат</span>
        </p>
        <h3>Информационная безопасность</h3>
        <p style="border-bottom: 2px solid #FFA51E;width:80px;">6B06301</p>
        <router-link to="#application"><button>Получить консультацию</button></router-link>
      </div>
    </div>
    <div class="container px-5 ">
        <p><b class="blue_bold">Цель образовательной программы</b> "Информационная безопасность" – подготовка высококвалифицированных специалистов в области информационной безопасности, способных решать практически любые комплексные задачи, связанные с</p>
        <ul>
          <li>защитой,</li>
          <li>надежным хранением,</li>
          <li>передачей и обработкой информации,</li>
        </ul>
        <p>опираясь при этом на самые передовые достижения в области математики и информационных технологий, и используя современные аппаратно-программные средства.</p>

        <p>Специалист по информационной безопасности обеспечивает:</p>
        <ul>
          <li>комплексную защиту информации,</li>
          <li>проводит аудит существующей системы безопасности,</li>
          <li>анализирует информационные риски</li>
          <li>проводит мониторинг системы информационной безопасности</li>
        </ul>

        <p>и в соответствии с этим разрабатывает и внедряет мероприятия по обеспечению информационной безопасности компании.</p>

        <p><b class="blue_bold">Задачи профессиональной деятельности:</b></p>
        <ul>
          <li>внедрение и поддержка защиты от несанкционированного доступа;</li>
          <li>защита персональных данных;</li>
          <li>проектирование корпоративной комплексной системы защиты информации;</li>
          <li>создание многоуровневой системы защиты информации (логины и пароли, идентификация по номерам телефона, по отпечатку пальца, по сетчатке глаза и др.);</li>
          <li>исследование составных частей системы (сайта, сервиса, автоматизированной системы в компании) на наличие уязвимостей и устранение выявленных поломок;</li>
          <li>проектирование систем физической защиты информации;</li>
          <li>предотвращение и устранение последствий взломов, если они произошли;</li>
          <li>разработка и внедрение новых регламентов по обеспечению защиты информации;</li>
          <li>работа с пользователями системы, с целью объяснения важности соблюдения защитных мер;</li>
          <li>ведение документации и подготовка отчетов по состоянию безопасности IT-систем;</li>
          <li>настройка сети, необходимо предусмотреть ошибки и потенциальные баги, развертывание и запуск технологии мониторинга подключений;</li>
          <li>изучение архитектуры и готового кода с целью нахождения уязвимостей кода и составление технического задания на устранение</li>
        </ul>



        <p><b class="blue_bold">Варианты трудоустройства:</b></p>
        <ul>
        <li>в банковской сфере (Kaspi Bank, Банк ЦентрКредит, Halyk Bank, ДО АО Банк ВТБ Казахстан, и др);</li>
        <li>в сфере телекоммуникаций и связи - КазахТелеком, КазТелепорт, К-СеІІ, Beeline, Dalacom;</li>
        <li>в ведущих компьютерных компаниях ЦАРКА, Alsi, Logicom, RealSoft, «ICORE-Integration»;</li>
        <li>в государственных структурах НИТ, МВД, Налоговый комитет, КНБ;</li>
        <li>компании, связанные с защитой информации, сетевым администрированием, администрированием баз данных, разработкой программного обеспечения.</li>
        </ul>

    </div>
    <div class="wrapper_four container py-5 mt-3" id="profession" style="height: auto;">
      <Partners/>
    </div>
    <ContactForm/>
  </div>
</template>

<script>
  import ContactForm from "../../../components/ContactForm";
  import Partners from "../../../components/Partners";

  export default {
    name: "ItSecurityPage",
    components: {
      ContactForm,
      Partners
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './it-security.scss';
@import '../../landing/landing.scss';
</style>