<template>
  <div class="wrapper_five container py-5" id="application">
    <div class="title">
      <h1>Оставьте заявку и получите <span>бесплатную консультацию</span></h1>
      <p>Мы поможем вам выбрать программу для достижения ваших целей, предоставим информацию о процессе обучения и ответим на все ваши вопросы.</p>
    </div>

    <div class="form d-flex flex-column justify-content-start align-items-start">
      <p>Ваше полное ФИО (по удостоверению)</p>
      <input v-model="name" type="text" placeholder="Алмаз" required>

      <p class="pt-3">Телефон</p>
      <input v-mask="'+7(###)###-##-##'" v-model="phone" type="text" placeholder="+7 (777) 777-77-77" required>

      <p class="pt-3">Email</p>
      <input v-model="email" type="text" placeholder="Введите Ваш email адрес" required>

      <p class="pt-3 d-flex align-items-center">
        <input type="checkbox" v-model="hasPromoCode" id="promoCodeCheckbox">
        <label for="promoCodeCheckbox">Есть промокод?</label>
      </p>

      <div v-if="hasPromoCode" class="pt-3">
        <p>Промокод</p>
        <input v-model="promoCode" type="text" placeholder="Введите ваш промокод">
      </div>

      <button @click="submitForm" class="mt-5">Отправить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactFormComponent",
  data(){
    return {
      name: '',
      phone: '',
      email: '',
      hasPromoCode: false,
      promoCode: ''
    };
  },
  methods: {
    async submitForm() {
      if(this.name=='' || this.phone=='' || this.email==''){
        alert("Заполните все поля");
      }
      else{
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('phone', this.phone); // Добавляем данные телефона
        formData.append('email', this.email);

        if (this.hasPromoCode) {
          formData.append('promoCode', this.promoCode);
        }

        try {
          const response = await fetch('https://adway.edu.kz/theme/space/ajax_form.php', { // Замените URL_API на фактический адрес вашего API
            method: 'POST',
            body: formData, // Используем FormData для отправки
          });
          const result = await response.json();
          if (result && result.result === 1) {
            alert('Форма успешно отправлена: ' + result.msg);
          } else {
            throw new Error(result.msg);
          }
        } catch (error) {
          console.error('Ошибка при отправке формы: ', error);
          alert('Ошибка при отправке формы: ' + error.message);
        }
      }
    }
  }
}
</script>